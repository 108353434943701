import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/app.css"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
    </>
  )
}

export default Layout
